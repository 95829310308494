<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="8"
        md="6"
      >
        <h4>Lokasi Pemesanan</h4>
      </b-col>
      <b-col
        cols="12"
        lg="4"
        md="6"
      >
        <cari-mitra
          :customer="customer"
          :vertikal="vertikal"
          :alamat="alamat"
          @arahkanOrderan="arahkanOrderan"
        />
      </b-col>
    </b-row>
    <l-map
      :zoom="zoom"
      :center="center"
      class="mt-2"
    >
      <l-tile-layer :url="url" />
      <l-marker :lat-lng="customerLatLng">
        <l-popup>
          <h6>Lokasi Customer</h6>
          {{ alamat }}
        </l-popup>
      </l-marker>
      <l-marker
        v-if="mitra"
        :lat-lng="mitraLatLng"
      >
        <l-popup>
          <h6>Lokasi Mitra</h6>
        </l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import 'leaflet/dist/leaflet.css'
import {
  LMap, LTileLayer, LMarker, LPopup,
} from 'vue2-leaflet'
import { BCol, BRow } from 'bootstrap-vue'
import CariMitra from '../reservasi/CariMitra.vue'

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    BCol,
    BRow,
    CariMitra,
  },
  props: ['customer', 'alamat', 'mitra', 'vertikal'],
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 12,
      center: [this.customer.latitude, this.customer.longitude],
      customerLatLng: [this.customer.latitude, this.customer.longitude, { draggable: 'true' }],
      mitraLatLng: [this.mitra?.latitude, this.mitra?.longitude, { draggable: 'true' }],
      mitraAround: [],
      btnloading: false,
    }
  },
  methods: {
    vertikalMitra(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }

      return 'Layanan Belum Diisi'
    },
    arahkanOrderan(mitra) {
      this.$emit('arahkanOrderan', mitra)
    },
    statusMitra(status) {
      if (status === 1) return { variant: 'light-info', display: 'Sedang Diproses' }
      if (status === 2) return { variant: 'light-info', display: 'Sedang Diverifikasi' }
      if (status === 3) return { variant: 'light-danger', display: 'Ditolak' }
      if (status === 4) return { variant: 'light-primary', display: 'Aktif' }
      if (status === 5) return { variant: 'light-warning', display: 'Saldo Kurang' }
      if (status === 6) return { variant: 'light-warning', display: 'Harus Konseling' }
      if (status === 7) return { variant: 'light-warning', display: 'Perlu Retraining' }
      if (status === 8) return { variant: 'light-danger', display: 'Dinonaktifkan' }
      if (status === 9) return { variant: 'light-danger', display: 'Diblokir' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
  },
}
</script>

<style lang="scss" scoped>
.vue2leaflet-map{
  &.leaflet-container{
    height: 350px;
  }
}
</style>

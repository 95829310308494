<template>
  <section class="invoice-preview-wrapper">
    <div class="d-flex">
      <feather-icon
        icon="ArrowLeftIcon"
        size="24"
        class="align-middle text-primary mr-2 mb-2 back"
        @click="$router.go(-1)"
      />
      <h3>Detail Order</h3>
    </div>
    <b-row
      class="invoice-preview"
    >
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <h3 class="text-primary">
                  Detail Order
                </h3>
                <h5 class="font-weight-bolder">
                  {{ vertikalLayanan(orderData.vertikal) }}
                </h5>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <h4 class="text-secondary text-lg-right mt-25 m-lg-0">
                  {{ orderData.nomor_order }}
                </h4>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Tanggal
                  </p>
                  <p class="invoice-date">
                    {{ orderData.waktu }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Status
                  </p>
                  <b-badge
                    pill
                    :variant="statusOrder(orderData.status).variant"
                  >
                    {{ statusOrder(orderData.status).display }}
                  </b-badge>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Untuk Kapan
                  </p>
                  <p class="invoice-date">
                    {{ getDate(orderData.pesanan_terjadwal) }}
                  </p>
                </div>
              </b-col>
              <div
                v-for="gen in orderData.layanan"
                :key="gen.kode"
                class="mt-md-0 mt-2 mr-2 ml-auto"
              >
                <div class="invoice-date-wrapper">
                  <p class="card-text mb-25">
                    Gender Mitra :
                  </p>
                  <p class="invoice-date">
                    {{ genderMitra(gen.gender_mitra) }}
                  </p>
                </div>
                <div class="d-flex invoice-date-wrapper">
                  <p class="card-text mb-25">
                    Gender Customer :
                  </p>
                  <p class="invoice-date">
                    {{ genderCustomer(gen.gender_customer) }}
                  </p>
                </div>
              </div>
            </b-row>
          </b-card-body>

          <hr>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                lg="6"
                class="pr-2"
              >
                <h6 class="mb-1 text-primary">
                  Detail Customer
                </h6>
                <h6 class="mb-25">
                  {{ orderData.nama_customer }}
                </h6>
                <p class="card-text mb-25">
                  {{ orderData.no_hp_customer }}
                </p>
                <p class="card-text mb-25">
                  {{ orderData.alamat_customer }}
                </p>
                <b-card
                  class="shadow-lg p-1 mt-50 mb-2 mb-lg-0"
                  no-body
                  border-variant="light"
                >
                  <small>Catatan Lokasi</small>
                  {{ orderData.detail_alamat }}
                </b-card>
              </b-col>
              <b-col
                cols="12"
                lg="6"
                class="pr-2"
              >
                <div>
                  <h6 class="mb-1 text-primary">
                    Detail Mitra
                  </h6>
                  <div class="d-flex">
                    <b-avatar
                      size="36"
                      :src="orderData.foto_mitra"
                      :variant="`light-primary`"
                      class="mr-1"
                      style="cursor: pointer"
                      @click="detailMitra(orderData.no_hp_mitra)"
                    />
                    <div>
                      <h6 class="mb-25">
                        {{ orderData.nama_mitra }}
                      </h6>
                      <p class="card-text mb-25">
                        {{ orderData.no_hp_mitra }}
                      </p>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <hr>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="6"
              >
                <h6 class="mb-1 text-primary">
                  Detail Layanan
                </h6>
                <ol class="p-0 pl-1">
                  <li
                    v-for="item in orderData.layanan"
                    :key="item.kode"
                  >
                    <h5 class="font-weight-bolder">
                      {{ item.nama_layanan }}
                    </h5>
                    <p class="mb-25">
                      {{ item.opsi.nama }} • Rp. {{ item.opsi.harga }}
                    </p>
                    <div v-if="item.extra.length">
                      <small class="text-secondary">Extra</small>
                      <small
                        v-for="ex in item.extra"
                        :key="ex.nama"
                        class="d-block"
                      >
                        + {{ ex.nama }} • Rp. {{ ex.harga }}
                      </small>
                    </div>
                  </li>
                </ol>
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <h6 class="mb-1 text-primary">
                  Detail Pembayaran
                </h6>
                <div class="d-flex justify-content-between mb-25">
                  <p class="m-0">
                    Metode Pembayaran
                  </p>
                  <h5 class="m-0 font-weight-medium">
                    {{ orderData.pembayaran }}
                  </h5>
                </div>
                <div class="d-flex justify-content-between mb-25">
                  <p class="m-0">
                    Total
                  </p>
                  <h5 class="m-0 font-weight-bolder">
                    Rp. {{ orderData.total }}
                  </h5>
                </div>
                <div v-if="orderData.promo">
                  <div class="d-flex justify-content-between mb-25">
                    <p class="m-0">
                      Promo yang dipakai
                    </p>
                    <p class="m-0 text-uppercase">
                      {{ orderData.kode_promo }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between mb-25">
                    <p class="m-0">
                      Potongan
                    </p>
                    <p class="m-0">
                      Rp. {{ orderData.potongan }}
                    </p>
                  </div>
                  <div class="d-flex justify-content-between mb-25">
                    <p class="m-0">
                      Total setelah promo
                    </p>
                    <h4 class="m-0 font-weight-bolder text-uppercase">
                      Rp. {{ orderData.total_setelah_promo }}
                    </h4>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <hr>
          <b-card-body class="pt-0">
            <leaflet-popup
              v-if="orderData && orderData.koordinat_customer"
              :id="id"
              :customer="orderData.koordinat_customer"
              :alamat="orderData.alamat_customer"
              :mitra="orderData.koordinat_mitra"
              :vertikal="orderData.vertikal"
              @arahkanOrderan="arahkanOrderan"
            />
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <b-button
            v-if="orderData.status < 7 && orderData.status > 1"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            block
            @click="selesaikanPesanan()"
          >
            Selesaikan Pesanan
          </b-button>
          <hr v-if="orderData.status < 7 && orderData.status > 0">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-success"
            block
            @click="tampilanChat()"
          >
            Tampilkan Chat
          </b-button>
          <b-dropdown
            v-if="permission.pesananCancel"
            text="Cancel Pesanan"
            block
            variant="outline-danger"
            class="mb-1 mt-1"
          >
            <b-dropdown-item
              @click="cancelPesanan()"
            >
              Cancel dari sistem
            </b-dropdown-item>
            <b-dropdown-item
              @click="cancelCustomer()"
            >
              Cancel dari customer
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-if="permission.pesananDelete"
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-75"
            block
          >
            Hapus Pesanan
          </b-button>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <b-card no-body>
          <b-card-body class="p-0 p-1">
            <h6>Notifikasi Mitra Aktif</h6>
          </b-card-body>
          <b-card-body
            v-for="(notif, index) in orderData.z_sentTo"
            :key="notif.no_hp + index"
            class="p-0 p-1"
          >
            <b-row>
              <b-col
                cols="12"
                md="8"
              >
                <h6
                  class="text-primary font-weight-bolder m-0 mb-25"
                  style="cursor: pointer"
                  @click="detailMitra(notif.no_hp)"
                >
                  {{ notif.nama }}
                </h6>
                <small>{{ notif.no_hp }} • {{ notif.waktu.toDate().toLocaleString() }}</small>
              </b-col>
              <b-col
                cols="12"
                md="4"
              >
                <b-button
                  class="text-align-bottom-right"
                  variant="outline-success"
                  size="sm"
                  @click="arahkanMitra(notif.no_hp)"
                >
                  Arahkan orderan
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <b-card no-body>
          <b-card-body class="p-0 p-1">
            <h6>Notifikasi Mitra Non Aktif</h6>
          </b-card-body>

          <b-card-body>
            <h6
              class="text-primary font-weight-bolder m-0 mb-25"
              style="cursor: pointer"
            >
              <span>sasini o kario</span>
            </h6>
            <small>089525620896 • 12/06/2233, 11:22:45 PM</small>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <vue-bottom-sheet
      ref="tampilkanChat"
      :rounded="false"
      :click-to-close="false"
      variant="success"
      class="mb-75"
      block
    >
      <div class="bottom-sheet">
        <div class="d-flex justify-content-between mb-1 align-content-center">
          <h4>Tampilan Chat</h4>
          <feather-icon
            icon="XIcon"
            size="28"
            class="text-primary"
            @click="closeTampilanChat"
          />
        </div>
        <div class="d-flex flex-column">
          <b-card
            v-for="pesan in chat"
            :key="pesan.sentTime"
            no-body
            :bg-variant="pesan.sendBy === orderData.uid_customer ? 'primary' : 'white'"
            class="p-1"
            :class="[pesan.sendBy === orderData.uid_customer ? 'mr-auto text-white' : 'ml-auto text-right', pesan.message.length > 75 ? 'w-75': '']"
          >
            <p
              class="m-0"
            >
              {{ pesan.message }}
            </p>
            <small
              class="mt-25"
              style="fontSize: 11px"
            >
              {{ new Date(pesan.sentTime).toLocaleDateString("en-US") + " " + ('0'+new Date(pesan.sentTime).getHours()).slice(-2) + ":" + ('0'+new Date(pesan.sentTime).getMinutes()).slice(-2) }}
            </small>
          </b-card>
        </div>
      </div>
    </vue-bottom-sheet>
  </section>
</template>

<script>
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  VBToggle,
  BBadge,
  BAvatar,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueBottomSheet from '@webzlodimir/vue-bottom-sheet'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { db } from '@/firebase'
import permission from '@/permission'
import LeafletPopup from './LeafletPopup.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BBadge,
    BAvatar,
    BDropdown,
    BDropdownItem,

    VueBottomSheet,
    LeafletPopup,
  },
  props: ['id'],
  data() {
    return {
      permission: permission.access,
      orderData: '',
      chat: [],
    }
  },

  created() {
    this.getOrder()
    this.getChat()
  },
  methods: {
    vertikalLayanan(vl) {
      if (vl) {
        const vertikalVariant = {
          vl01: 'Akupunktur',
          vl02: 'Auto',
          vl03: 'Barber',
          vl04: 'Bekam',
          vl05: 'Beauty',
          vl06: 'Cleaning',
          vl07: 'Massage',
          vl08: 'Cuci AC',
        }
        return vertikalVariant[vl]
      }
      return 'Layanan Belum Diisi'
    },
    detailMitra(noHp) {
      this.$router.push({ name: 'DetailMitra', params: { id: noHp } })
    },
    getOrder() {
      db.collection('orders').doc(this.id).get().then(snapshot => {
        this.orderData = snapshot.data()
      })
    },
    getChat() {
      db.collection('orders').doc(this.id).collection('chat')
        .orderBy('sentTime', 'asc')
        .onSnapshot(snapshot => {
          snapshot.docs.forEach(doc => {
            this.chat.push(doc.data())
          })
        })
    },
    getDate(unix) {
      const timestamp = unix
      const dateObject = new Date(timestamp)

      return dateObject.toLocaleString('id-ID', {
        weekday: 'long', day: 'numeric', month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric',
      })
    },
    genderMitra(mitra) {
      const variantGender = {
        0: 'Pria',
        1: 'Wanita',
        2: 'Bebas',
      }
      return variantGender[mitra]
    },
    genderCustomer(customer) {
      const variantGender = {
        0: 'Pria',
        1: 'Wanita',
        2: 'Bebas',
      }
      return variantGender[customer]
    },
    statusOrder(status) {
      if (status === 0) return { variant: 'light-danger', display: 'Membuat proses' }
      if (status === 1) return { variant: 'light-primary', display: 'Mencari Mitra' }
      if (status === 2) return { variant: 'light-primary', display: 'Terjadwal' }
      if (status === 3) return { variant: 'light-primary', display: 'Mitra dalam perjalanan' }
      if (status === 4) return { variant: 'light-primary', display: 'Mitra sudah sampai' }
      if (status === 5) return { variant: 'light-primary', display: 'Sedang mengerjakan' }
      if (status === 6) return { variant: 'light-primary', display: 'Menunggu pembayaran' }
      if (status === 7) return { variant: 'light-primary', display: 'Selesai' }
      if (status === 8) return { variant: 'danger', display: 'Dibatalkan oleh customer' }
      if (status === 9) return { variant: 'danger', display: 'Dibatalkan oleh sistem' }
      return { variant: 'light-secondary', display: 'tidak diketahui' }
    },
    tampilanChat() {
      this.$refs.tampilkanChat.open()
    },
    closeTampilanChat() {
      this.$refs.tampilkanChat.close()
    },
    arahkanMitra(noHp) {
      this.$swal({
        title: 'Arahkan pesanan ke mitra ini?',
        text: 'Konfirmasi jika anda ingin mengarahkan pesanan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.orderData.id = this.id
          db.collection('mitra').doc(noHp).get().then(mitra => {
            db.collection('orders').doc(this.id).set({
              uid_mitra: mitra.data().uid,
              nama_mitra: mitra.data().nama,
              foto_mitra: mitra.data().fotoProfil,
              no_hp_mitra: mitra.data().no_hp,
              status: 2,
            }, { merge: true }).then(async () => {
              const options = {
                url: 'http://localhost:5001/mecare-life/us-central1/arahkanOrderMitra',
                method: 'POST',
                data: {
                  mitra: mitra.data(),
                  orderan: this.orderData,
                },
              }
              this.getOrder()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengarahkan mitra ke orderan ini',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
              await this.$axios(options).then(value => {
                if (value.data.status === 'ok') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil mengirim notifikasi ke mitra.',
                      icon: 'bellIcon',
                      variant: 'success',
                      position: 'bottom',
                    },
                  })
                }
              }).catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal mengirimkan notifikasi part 2',
                    icon: 'BellIcon',
                    variant: 'danger',
                    position: 'bottom',
                  },
                })
              })
            })
          })
        }
      })
    },

    arahkanOrderan(datamitra) {
      this.$swal({
        title: 'Arahkan pesanan ke mitra ini?',
        text: 'Konfirmasi jika anda ingin mengarahkan pesanan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          this.orderData.id = this.id
          db.collection('mitra').doc(datamitra.no_hp).get().then(mitra => {
            db.collection('orders').doc(this.id).set({
              uid_mitra: mitra.data().uid,
              nama_mitra: mitra.data().nama,
              foto_mitra: mitra.data().fotoProfil,
              no_hp_mitra: mitra.data().no_hp,
              status: 2,
            }, { merge: true }).then(async () => {
              const options = {
                url: 'https://us-central1-mecare-life.cloudfunctions.net/arahkanOrderMitra',
                method: 'POST',
                data: {
                  mitra: mitra.data(),
                  orderan: this.orderData,
                },
              }
              this.getOrder()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil mengarahkan mitra ke orderan ini',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
              await this.$axios(options).then(value => {
                if (value.data.status === 'ok') {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil mengirimkan notifikasi ke mitra.',
                      icon: 'BellIcon',
                      variant: 'success',
                      position: 'bottom',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Gagal mengirimkan notifikasi',
                      icon: 'BellIcon',
                      variant: 'danger',
                      position: 'bottom',
                    },
                  })
                }
              }).catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal mengirimkan notifikasi',
                    icon: 'BellIcon',
                    variant: 'danger',
                    position: 'bottom',
                  },
                })
              })
            })
          })
        }
      })
    },

    async selesaikanPesanan() {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/prosesTandonHTTP',
        method: 'POST',
        data: {
          nomorOrder: this.id,
        },
      }
      this.$swal({
        title: 'Selesaikan pesanan?',
        text: 'Konfirmasi jika anda ingin menyelesaikan pesanan ini',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('orders').doc(this.id).update({
            status: 7,
          }).then(async () => {
            await this.$axios(options).then(value => {
              if (value.data === 'ok') {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Berhasil menyelesaikan orderan!',
                    icon: 'BellIcon',
                    variant: 'success',
                    position: 'bottom',
                  },
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Gagal. Orderan sudah diselesaikan.',
                    icon: 'BellIcon',
                    variant: 'danger',
                    position: 'bottom',
                  },
                })
              }

              this.getOrder()
            })
          })
        }
      })
    },
    countDistance(distance) {
      if (distance < 1) {
        return `${Math.ceil(distance * 1000)} M`
      }
      return `${Math.floor(distance * 100) / 100} KM`
    },
    cancelPesanan() {
      const options = {
        url: 'https://us-central1-mecare-life.cloudfunctions.net/cancelOrderHTTP',
        method: 'POST',
        data: {
          nomorOrder: this.id,
        },
      }
      this.$swal({
        title: 'Cancel Pesanan ini?',
        text: 'konfirmasi jika anda ingin Mengcancel Pesanan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          db.collection('orders').doc(this.id).update({
            status: 9,
          }).then(async () => {
            await this.$axios(options).then(() => {
              this.getOrder()
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Pesanan berhasil dicancel',
                  icon: 'CheckIcon',
                  variant: 'success',
                  position: 'bottom',
                },
              })
            })
          })
        }
      })
    },
    cancelCustomer() {
      this.$swal({
        title: 'Cancel Pesanan ini?',
        text: 'konfirmasi jika anda ingin Mengcancel Pesanan',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
      }).then(async () => {
        const options = {
          url: 'https://us-central1-mecare-life.cloudfunctions.net/cancelOrderCustomer',
          method: 'POST',
          data: {
            nomorOrder: this.id,
          },
        }
        await this.$axios(options).then(() => {
          this.getOrder()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Pesanan berhasil dicancel',
              icon: 'CheckIcon',
              variant: 'success',
              position: 'bottom',
            },
          })
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@import '@core/scss/vue/libs/map-leaflet.scss';
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .bottom-sheet {
    padding: 16px 16px 200px 16px
  }
}
</style>
